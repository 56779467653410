<template>
  <div>
    <template v-if="activateTooltip">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <span v-on="on" style="height: fit-content; width: fit-content;">
            <v-btn v-bind="$attrs" @click="onClick">
              {{text}}
              <slot></slot>
            </v-btn>
            </span>
        </template>
        <span>{{tooltip}}</span>
      </v-tooltip>
    </template>
    <template v-else>
      <v-btn v-bind="$attrs" @click="onClick">
        {{text}}
        <slot></slot>
      </v-btn>
    </template>
  </div>
</template>

<script>
  export default {
    name: 'AppButtonTooltip',

    props: {
        text: String,
        tooltip: {type: String, required: true},
        onClick: {type: Function, required: true},
    },

    data: () => ({
      activateTooltip: false, // Tooltips are kind of annoying
    })
  }
</script>