<style>
/* v-slider changes, changes can only be in the scoped css if we use /deep/ but vs code marks that as an error =/ */
.v-slider__tick--filled {
  background-color: #FBC02D; /* yellow darken-2 */
}

.v-slider__tick-label {
  padding-left: 5px;
}

.v-slider__tick-label::after {
  color: #FBC02D; /* yellow darken-2 */
  content: "\F09BB";
  transform: rotate(90deg);
  display: inline-block;
  font: normal normal normal 24px/1 "Material Design Icons";
  text-rendering: auto;
  line-height: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>

<style scoped>
.moreSpace {
  /*/
    https://vuetifyjs.com/en/components/cards/
    Also applies special margin to buttons so that they properly line up with other card content areas.
    ... doesn't work, so we have to set the margings ourself :(
  */
  /*
  padding: 0px 0px 0px 16px !important;
  */
  margin: 0px 0px 0px 8px !important;
}

.transparentOverlay {
  position: fixed;
  z-index: 999;
  opacity: 0;
  cursor: pointer;
  background-color: #477B7E;
}

.transparentOverlaySuccess {
  opacity: 0.31;
  background: #4caf50;
}

.transparentOverlaySelected {
  background-color: #477B7E;
  opacity: 0.5;
}

@media (hover: hover) {
  /*
   * There is an iOS "Bug" where a click event won't be triggered if the element has a css hover effect. 
   * A touch will trigger the hover effect and will stop there, so needed click event won't be triggered.
   * Thanks to: https://stackoverflow.com/a/65528790 we got this solution!
   */

  .transparentOverlay:hover {
    background-color: #477B7E;
    opacity: 0.5;
  }
}

.withoutBottomPadding {
  padding-bottom: 0px;
}

.workOrder {
  font-size: 16px;
  background-color: #f5f5f5;
}
</style>

<template>
  <div class="lbst-border">
    <!-- workOrder -->
    <v-row no-gutters style="flex-wrap: nowrap" class="justify-space-between">
      <v-card outlined class="flex-grow-1">
        <v-card-text class="workOrder" v-html="workOrder"></v-card-text>
      </v-card>

      <!-- Info-Dialog -->
      <v-card outlined>
        <v-row no-gutters align="center" style="height: 100%">
          <AppDialogInfo
            title="Video-Anleitung für das interaktive Element"
            :elementId="this.id"
          >
            <video
              preload="auto"
              controls
              width="60%"
              style="margin-left: 20%"
              @play="
                videoPlayerEvent($event, 'Hilfevideo_LBST_Schieberegler.mp4')
              "
              @fullscreenchange="
                videoPlayerEvent($event, 'Hilfevideo_LBST_Schieberegler.mp4')
              "
              @webkitfullscreenchange="
                videoPlayerEvent($event, 'Hilfevideo_LBST_Schieberegler.mp4')
              "
              @mozfullscreenchange="
                videoPlayerEvent($event, 'Hilfevideo_LBST_Schieberegler.mp4')
              "
              @msfullscreenchange="
                videoPlayerEvent($event, 'Hilfevideo_LBST_Schieberegler.mp4')
              "
              @ended="
                videoPlayerEvent($event, 'Hilfevideo_LBST_Schieberegler.mp4')
              "
              @pause="
                videoPlayerEvent($event, 'Hilfevideo_LBST_Schieberegler.mp4')
              "
              @seeking="
                videoPlayerEvent($event, 'Hilfevideo_LBST_Schieberegler.mp4')
              "
              @enterpictureinpicture="
                videoPlayerEvent($event, 'Hilfevideo_LBST_Schieberegler.mp4')
              "
              @leavepictureinpicture="
                videoPlayerEvent($event, 'Hilfevideo_LBST_Schieberegler.mp4')
              "
            >
              <!-- @seeking="videoPlayerEvent($event, '4er-System um 1 weiterzählen_201103_YB.mp4')" -->
              <source
                src="@/assets/hilfevideos/Hilfevideo_LBST_Schieberegler.mp4"
                type="video/mp4"
              />
            </video>
          </AppDialogInfo>
        </v-row>
      </v-card>
    </v-row>

    <!-- content slides -->
    <v-row no-gutters justify="center">
      <v-col :cols="12" md="8">
        <!-- overlays on top of answers if the slide is an intervention -->
        <div
          v-for="option in slides[slider].interventionOptions"
          :key="option.title"
          :style="transparentOverlayInterventionStyle(option)"
          class="transparentOverlay"
          :class="[
            {
              transparentOverlaySuccess:
                !isAnimationRunning &&
                option.correct &&
                solvedInterventions.includes(slider),
            },
            { transparentOverlaySelected: wrongAnswerChosen == option.title },
          ]"
          @click="
            resolveIntervention(slider, $refs.myVueperSlides, option.title)
          "
        ></div>
        <vueper-slides
          ref="myVueperSlides"
          :slide-ratio="originalSlideHeight / originalSlideWidth"
          fade
          :dragging-distance="50"
          class="no-shadow"
          transition-speed="200"
          :infinite="false"
          disable-arrows-on-edges
          :arrows="false"
          :bullets="false"
          @slide="updateSlider($event, $refs.myVueperSlides)"
          @before-slide="updateSliderBackwards($event)"
        >
          <vueper-slide
            v-for="(slide, i) in slides"
            :key="i"
            :title="slide.title"
            ref="myVueperSlidesSlide"
          >
            <template v-slot:content>
              <template v-if="slide.animation">
                <video
                  @ended="endedAnimation"
                  :ref="'animation' + i"
                  preload="auto"
                  style="height: 100%"
                  muted
                >
                  <source :src="slide.animation" />
                  <source
                    v-if="slide.animationAlt"
                    :src="slide.animationAlt"
                    type="video/mp4"
                  />
                </video>
              </template>
              <template v-else>
                <video :poster="slide.image" muted style="height: 100%"></video>
              </template>
            </template>
          </vueper-slide>
        </vueper-slides>
      </v-col>
    </v-row>

    <!-- content feedback -->
    <v-row no-gutters>
      <v-col>
        <v-card
          ref="feedbackArea"
          v-show="isIntervantionSlide || onceFeedbackAreaShown"
          outlined
        >
          <v-row
            no-gutters
            :style="'min-height: ' + feedbackAreaMinHeight + 'px'"
          >
            <v-col cols="12" v-if="isIntervantionSlide">
              <v-card-text
                v-html="slides[slider].interventionText"
                :class="{
                  withoutBottomPadding: displayHint || success,
                }"
              />
            </v-col>
            <v-row no-gutters align="center">
              <v-col cols="12" v-if="displayHint || success">
                <v-card-text>
                  <!-- hint -->
                  <AppAlertTooltip
                    v-show="displayHint"
                    :textHtml="hintText"
                    :additionalInformationHtml="toolTip"
                    :expandable="false"
                    :expandableInitialOpen="true"
                    type="info"
                    :parentId="id"
                  />

                  <!-- success -->
                  <AppAlertTooltip
                    v-show="success"
                    :textHtml="successText"
                    :additionalInformationHtml="toolTip"
                    :expandable="false"
                    :expandableInitialOpen="true"
                    type="success"
                    :parentId="id"
                  />
                </v-card-text>
              </v-col>
            </v-row>
          </v-row>
          <resize-observer @notify="handleResize" />
        </v-card>
      </v-col>
    </v-row>

    <!-- navigation slider -->
    <v-row no-gutters>
      <v-col :cols="12">
        <v-slider
          v-model.lazy="slider"
          ref="myVSlider"
          :tick-labels="tickLabels"
          :key="forceReRenderKey"
          ticks="always"
          tick-size="8"
          :min="0"
          :max="slides.length - 1"
          color="yellow darken-2"
          track-color="grey"
          @input="updateSlides($event, $refs.myVueperSlides, $refs.myVSlider)"
        ></v-slider>
      </v-col>
    </v-row>

    <!-- navigation buttons -->
    <v-row no-gutters>
      <v-col>
        <v-row no-gutters justify="space-between">
          <!-- <AppButtonTooltip :text="resetText" :tooltip="resetTooltip" :onClick="() => {reset($refs.myVueperSlides)}"></AppButtonTooltip> -->
          <AppButtonReset @click="reset($refs.myVueperSlides)" />

          <v-row no-gutters justify="end">
            <AppButtonTooltip
              :disabled="slider <= 0"
              :onClick="
                () => {
                  backToPreviousSolvedIntervention($refs.myVueperSlides);
                }
              "
              :text="startText"
              :tooltip="startTooltip"
              style="padding-right: 4px"
            />

            <AppButtonTooltip
              :disabled="slider <= 0"
              color="primary"
              :tooltip="previousTooltip"
              :onClick="previousSlideButton"
            >
              <v-icon>mdi-step-backward</v-icon>
            </AppButtonTooltip>

            <AppButtonTooltip
              :disabled="blockProgress || lastSlideReached"
              color="primary"
              :tooltip="nextTooltip"
              :onClick="nextSlideButton"
            >
              <v-icon>mdi-step-forward</v-icon>
            </AppButtonTooltip>
          </v-row>
        </v-row>
        <!-- Collapse for alternative representation -->
        <AppAlternativeContent
          :id="id"
          :exerciseId="id"
          :trials="trials"
          :solved="solved"
          :reset="resetLBST"
          v-on:reset="resetLBST = false"
        >
          <template v-slot:alternative-content>
            <slot name="alternative-content"></slot>
          </template>
        </AppAlternativeContent>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
import AppButtonTooltip from "@/common/AppButtonTooltip";
import AppAlertTooltip from "@/common/AppAlertTooltip";
import AppButtonReset from "@/common/AppButtonReset";
import AppDialogInfo from "@/common/AppDialogInfo";
import AppAlternativeContent from "@/common/AppAlternativeContent";

import AppHelper from "@/common/AppHelper";

export default {
  name: "LernbausteinSchieberegler",
  props: {
    resetText: { type: String, default: "RESET" },
    resetTooltip: { type: String, default: "Setzt den Lernbaustein zurück" },
    previousTooltip: { type: String, default: "Vorheriges Element anzeigen" },
    nextTooltip: { type: String, default: "Nächstes Element anzeigen" },
    startText: { type: String, default: "Zum Start der Teilaufgabe" },
    startTooltip: {
      type: String,
      default: "Zurück zum Start dieser Teilaufgabe",
    },
    workOrder: { type: String, required: true },
    slides: { type: Array, required: true },
    originalSlideWidth: { type: Number, required: true },
    originalSlideHeight: { type: Number, required: true },
    /* unique id for the store */
    id: { String, required: true },
  },
  components: {
    VueperSlides,
    VueperSlide,
    AppButtonTooltip,
    AppAlertTooltip,
    AppButtonReset,
    AppDialogInfo,
    AppAlternativeContent,
  },
  mounted: function () {
    this.restoreState();
  },
  data: () => ({
    // current slider position
    slider: 0,
    // we save which interventions were already solved therefore we don't have to solve them every time
    solvedInterventions: [],
    // help variable to force a re render (see usage)
    forceReRenderKey: 0,
    // current displayed hint text
    hintText: "",
    // variable which holds the tool tip which is displayed if you hover over the hint text
    toolTip: "",
    // text after a successfull intervention resolution
    successText: "",
    // indicator if a wrong answer was choosen and which wrong answer was chosen
    wrongAnswerChosen: false,
    // helper to set if an animation on a slide should be played
    playAnimation: true,
    // helper to see if currently an animation is running
    isAnimationRunning: false,
    // was the feedback area once shown, we let it stay visible
    onceFeedbackAreaShown: false,
    // size of the feedback area after an intervention was solved
    feedbackAreaMinHeight: "",
    // top position of the slide component on the html side
    topSlide: 0,
    // left position of the slide component on the html side
    leftSlide: 0,
    // width of the slide component on the html side
    widthSlide: 0,
    // height of the slide component on the html side
    heightSlide: 0,
    // indicator if we are going backwards
    goingBackwards: false,
    // number of wrong answers/trials
    trials: 0,
    // only show alternative content if trials-threshold is reached or LBST is solved,
    //if the LBST is solved show additional content
    solved: false,

    resetLBST: false,

    lastSlideReached: false,
  }),
  watch: {},
  computed: {
    blockProgress: function () {
      return (
        this.slides[this.slider].intervention &&
        !this.solvedInterventions.includes(this.slider)
      );
    },
    success: function () {
      // only show success alert if slide has success text or we correctly solved an intervention
      return (
        (this.slides[this.slider].intervention && // if we are on an intervention slide // AND
          this.solvedInterventions.includes(this.slider)) || // we already solved this intervention // OR
        (this.hintText.length == 0 && // we have no hint displayed // AND
          !this.goingBackwards && // we are not going backwards // AND
          this.slides[this.slider].successText) // this slide has an success text
      );
    },
    displayHint: function () {
      // only show hints while we are on a intervention slide and we have a hint text
      return this.slides[this.slider].intervention && this.hintText.length > 0;
    },
    tickLabels: function () {
      var tickLabelArray = [];
      for (var i = 0; i < this.slides.length; i++) {
        if (this.slides[i].intervention) {
          // tickLabelArray.push("Entscheidungspunkt");
          tickLabelArray.push(" "); // this is important to later set an icon with css
        } else {
          tickLabelArray.push("");
        }
      }
      return tickLabelArray;
    },
    isIntervantionSlide: function () {
      return this.slides[this.slider].intervention;
    },
  },

  created() {
    window.addEventListener(
      "resize",
      this.updatePositionForInterventionOverlay
    );
    //window.addEventListener('orientationchange', this.updatePositionForInterventionOverlay);
    window.addEventListener(
      "scroll",
      this.updatePositionForInterventionOverlay
    );
  },
  destroyed() {
    window.removeEventListener(
      "resize",
      this.updatePositionForInterventionOverlay
    );
    //window.removeEventListener('orientationchange', this.updatePositionForInterventionOverlay);
    window.removeEventListener(
      "scroll",
      this.updatePositionForInterventionOverlay
    );
  },
  methods: {
    nextSlideButton: function () {
      AppHelper.trackMatomoEvent(
        this,
        "LBSTSchieberegler",
        "id:2; Next-Button betätigt um Folie zu ändern;",
        this.id
      );
      this.$refs.myVueperSlides.next();
    },

    previousSlideButton: function () {
      AppHelper.trackMatomoEvent(
        this,
        "LBSTSchieberegler",
        "id:3; Previous-Button betätigt um Folie zu ändern;",
        this.id
      );
      this.$refs.myVueperSlides.previous();
    },

    updateSliderBackwards: function (event) {
      if (event.nextSlide.index < event.currentSlide.index) {
        // we are going back
        this.slider = event.nextSlide.index;

        this.playAnimation = false;
        // if we are on a slide that has an animation and before the animation is finished we go back to the slide before we had to wait until the animation is finished
        this.isAnimationRunning = false;
        this.goingBackwards = true;
      } else {
        this.goingBackwards = false;
        this.playAnimation = true;
      }
    },
    updateSlider: function (event, myVueperSlides) {
      // if the vueper-slides are changing, we are changing the slider
      if (
        this.blockProgress &&
        !this.slides[event.currentSlide.index].intervention
      ) {
        // if we block the progress we prevent from going to the next slide after the intervention slide
        // therefor the current slider value can be left untouched
        myVueperSlides.goToSlide(event.currentSlide.index - 1, { emit: false });
      } else {
        AppHelper.trackMatomoEvent(
          this,
          "LBSTSchieberegler",
          "id:4; Folie geändert auf:" + (event.currentSlide.index + 1) + ";",
          this.id
        ); //avoid 0 based index,
        this.slider = event.currentSlide.index;
        // remove hintText to remove alert
        this.hintText = "";
      }

      if (this.slides[this.slider].intervention) {
        this.onceFeedbackAreaShown = true;
        this.interventionOverview();
      }

      if (this.slider >= this.slides.length - 1) {
        if (!this.solved) {
          AppHelper.trackMatomoEvent(
            this,
            "LBSTSchieberegler",
            "id:1; Letzte Folie erreicht. LBST erfolgreich abgeschlossen.;",
            this.id
          );
          this.solved = true;
        }
        this.lastSlideReached = true;
      } else {
        this.lastSlideReached = false;
      }

      this.startAnimation();
      this.saveState();
    },
    updateSlides: function (slideIndex, myVueperSlides) {
      // if the v-slider is changing, we are changing the vueper-slides

      // we have to prevent to set the slider onto an invalid position
      if (
        this.sliderAfterIntervention(
          this.slides,
          this.solvedInterventions,
          slideIndex
        )
      ) {
        // we have to stay on the current page
        this.slider = myVueperSlides.slides.current;
        // TODO: get rid of forceReRenderKey, without it the slider will be updated eventhough we change the v-model value
        // so the slider will be displayed after the intervention point
        this.forceReRenderKey++;
      } else {
        AppHelper.trackMatomoEvent(
          this,
          "LBSTSchieberegler",
          "id:5; Slider betätigt um Folie zu ändern;",
          this.id
        );
        myVueperSlides.goToSlide(this.slider);
      }
    },

    sliderAfterIntervention: function (
      slides,
      solvedInterventions,
      slideIndex
    ) {
      for (var i = 0; i < slides.length; i++) {
        // we have to check if we want to reach a slide which is after an unsolved intervention point
        if (slides[i].intervention && !solvedInterventions.includes(i)) {
          if (i < slideIndex) {
            return true;
          }
        }
      }
      return false;
    },

    resolveIntervention: function (slider, myVueperSlides, option) {
      var currentSlide = this.slides[this.slider];
      var solvedInterventions = this.solvedInterventions;
      var that = this;

      AppHelper.trackMatomoEvent(
        this,
        "LBSTSchieberegler",
        "id:6; Antwort gewählt: " + option + ";",
        this.id
      );

      currentSlide.interventionOptions.forEach(function (interventionOption) {
        if (interventionOption.title === option) {
          if (interventionOption.correct) {
            solvedInterventions.push(slider);
            myVueperSlides.next();
            that.successText =
              that.slides[myVueperSlides.slides.current].successText;
            that.hintText = "";
            AppHelper.trackMatomoEvent(
              that,
              "LBSTSchieberegler",
              "id:7; Entscheidungspunkt korrekt gelöst;",
              that.id
            );
          } else {
            that.hintText = interventionOption.hintText;
            that.wrongAnswerChosen = interventionOption.title;
            that.trials++;
          }
          that.toolTip = interventionOption.toolTip;
        }
      });
      this.saveState();
    },

    startAnimation: function () {
      let animation = this.$refs["animation" + this.slider]
        ? this.$refs["animation" + this.slider][0]
        : false;
      if (this.playAnimation) {
        this.isAnimationRunning = true;
        if (animation) {
          animation.currentTime = 0; // ensure that the animation will be played from the beginning
          animation.play();
        }
      } else {
        if (animation && animation.duration) {
          // set the video to it's end position
          animation.currentTime = animation.duration;
        }
      }
    },

    interventionOverview: function () {
      this.updatePositionForInterventionOverlay();
      this.hintText = "";
      this.wrongAnswerChosen = false;
    },

    backToPreviousSolvedIntervention: function (myVueperSlides) {
      AppHelper.trackMatomoEvent(
        this,
        "LBSTSchieberegler",
        "id:8; Zum Start der Teilaufgabe;",
        this.id
      );
      if (this.solvedInterventions.length == 0) {
        // we have no solved interventions, so it has to be the first one and we can go safely back to the start
        this.slider = 0;
      } else {
        // find out which slide was the previous solved intervention
        let foundPreviousSolvedIntervention = false;
        for (let i = this.solvedInterventions.length - 1; i >= 0; i--) {
          // if we are on the slide we want to jump to we would want to go to the intervention point before, if there is one
          if (this.solvedInterventions[i] + 1 < this.slider) {
            // we have a previous solved intervention, we go back there but a slide after
            this.slider = this.solvedInterventions[i] + 1;
            foundPreviousSolvedIntervention = true;
            break;
          }
        }
        if (!foundPreviousSolvedIntervention) {
          // if we didn't find any previous solved intervention we just go back to the start
          this.slider = 0;
        }
      }
      myVueperSlides.goToSlide(this.slider);
    },

    reset: function (myVueperSlides) {
      AppHelper.trackMatomoEvent(
        this,
        "LBSTSchieberegler",
        "id:9; Reset;",
        this.id
      );
      this.solvedInterventions = [];
      this.slider = 0;
      myVueperSlides.goToSlide(this.slider);
      this.onceFeedbackAreaShown = false;
      this.feedbackAreaMinHeight = "0";
      this.trials = 0;
      this.solved = false;
      this.resetLBST = true;
      this.lastSlideReached = false;
      this.saveState();
    },

    updatePositionForInterventionOverlay: function () {
      if (this.isIntervantionSlide) {
        if (this.$refs.myVueperSlides) {
          //this.$refs['animation' + this.slider].$el.getClientRects()[0];
          let slideClientRects =
            this.$refs.myVueperSlides.$el.getClientRects()[0];
          this.topSlide = slideClientRects.top;
          this.leftSlide = slideClientRects.left;
          this.widthSlide = slideClientRects.width;
          this.heightSlide = slideClientRects.height;
        }
      }
    },

    transparentOverlayInterventionStyle: function (option) {
      // calcualte how much smaller we are from the original image
      let smallerFactor = this.widthSlide / this.originalSlideWidth;

      return {
        top: this.topSlide + option.top * smallerFactor + "px",
        left: this.leftSlide + option.left * smallerFactor + "px",
        width: option.width * smallerFactor + "px",
        height: option.height * smallerFactor + "px",
      };
    },

    handleResize({ height }) {
      if (this.feedbackAreaMinHeight < height) {
        // ToDo: somehow the feedback area bounces a litte. could be the used border, don't now yet.
        // let randomValue = 3;
        this.feedbackAreaMinHeight = height; // + randomValue;
      }
    },

    endedAnimation: function () {
      // the animation on this slide ended
      // we drop the events if on other slides an animation might have been ended
      if (this.$refs["animation" + this.slider]) {
        if (
          this.$refs["animation" + this.slider][0].currentSrc ==
          event.srcElement.currentSrc
        ) {
          this.isAnimationRunning = false;
        }
      }
    },
    restoreState: function () {
      const storeId = "LBSTSchieberegler" + this.id;

      const restoredState = this.$store.state.falediaState
        ? this.$store.state.falediaState[storeId]
        : false;

      if (restoredState && restoredState.data.slider) {
        // it is possible to receive an empty data object, so we check if there is any data by using the slider attribute

        this.slider = restoredState.data.slider;
        this.$refs["myVueperSlides"].goToSlide(restoredState.data.slider);

        // ATTENTION! How the Schieberegler works is we display and image or stay on the last videoframe if it is a video.
        // if we restore the state, and the slide has a video we want to display the last frame
        // the duration of the video is somehow not set, so not playing the video will have the result, that we stay on the first frame, not the last.
        // so, we have to set playAnimation to true, so if we call startAnimation the video will be played and is stopping on the last frame
        // https://goo.gl/xX8pDD another restrict is the autoplay policy. Chrome doesn't allow it the play unmuted videos automatically, so we have to mute the video (see video defintion in template)
        this.playAnimation = true;
        this.startAnimation();

        this.solvedInterventions = restoredState.data.solvedInterventions;
        // forceReRenderKey: 0,
        this.hintText = restoredState.data.hintText;
        this.toolTip = restoredState.data.toolTip;
        this.successText = restoredState.data.successText;
        this.wrongAnswerChosen = restoredState.data.wrongAnswerChosen;
        // this.playAnimation = restoredState.data.playAnimation;
        this.isAnimationRunning = restoredState.data.isAnimationRunning;
        this.onceFeedbackAreaShown = restoredState.data.onceFeedbackAreaShown;
        this.feedbackAreaMinHeight = restoredState.data.feedbackAreaMinHeight;
        // topSlide: 0,
        // leftSlide: 0,
        // widthSlide: 0,
        // heightSlide: 0,
        this.goingBackwards = restoredState.data.goingBackwards;
        this.solved = restoredState.data.solved;
        this.lastSlideReached = restoredState.data.lastSlideReached;
      }
      if (!this.$store.hasModule(["nested", storeId])) {
        this.$store.registerModule(["nested", storeId], {
          namespaced: true,
          state: {
            data: {}, // it's very imporant to create the necessary attributes before
          },
          mutations: {
            data(state, payload) {
              // state.data = payload;
              state.data = {
                slider: payload.slider,
                solvedInterventions: payload.solvedInterventions,
                // forceReRenderKey: 0,
                hintText: payload.hintText,
                toolTip: payload.toolTip,
                successText: payload.successText,
                wrongAnswerChosen: payload.wrongAnswerChosen,
                playAnimation: payload.playAnimation,
                isAnimationRunning: payload.isAnimationRunning,
                onceFeedbackAreaShown: payload.onceFeedbackAreaShown,
                feedbackAreaMinHeight: payload.feedbackAreaMinHeight,
                // topSlide: 0,
                // leftSlide: 0,
                // widthSlide: 0,
                // heightSlide: 0,
                goingBackwards: payload.goingBackwards,
                solved: payload.solved,
                lastSlideReached: payload.lastSlideReached,
              };
            },
          },
        });
        // VERY IMPORTANT! If missing this state is not registered in the nested store state and will not be send to the server if another component updates it state
        this.saveStateLocal(); // save the current state, so even if we don't change anything the last state will be send to the server
      }
    },
    saveStateLocal: function () {
      const storeId = "LBSTSchieberegler" + this.id;
      this.$store.commit("nested/" + storeId + "/data", { ...this._data });
    },
    saveState: function () {
      this.saveStateLocal();
      // send data to server
      const token = localStorage.getItem("jwt");
      try {
        this.$http
          .post("/user/state", this.$store.state.nested, {
            headers: {
              Authorization: token,
            },
          })
          .then((response) => {
            localStorage.setItem("jwt", response.data.token);
            this.$store.commit("falediaState", this.$store.state.nested);
          })
          .catch(() => {
            // if we have an error, we don't save the state
            // console.log(err);
            // network error can be invalid token, be aware!
          });
      } catch (err) {
        // if we have an error, we don't save the state
        // console.log("down " + err);
      }
    },
    // restoreSlides(myVueperSlides) {
    //   myVueperSlides.goToSlide(this.slider);
    // },

    videoPlayerEvent(event, name) {
      AppHelper.videoPlayerEvent(this, event, name);
    },
  },
};
</script>
